<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <v-snackbar
        top
        right
        fixed
        v-model="snackbarDownload"
        :color="snackbarDownloadColor"
        timeout="-1"
        elevation="24"
      >
        <h4>{{ snackDownloadTitle }}</h4>
        <h5>{{ snackDownloadText }}</h5>
        <v-progress-linear indeterminate color="white darken-2" />
      </v-snackbar>

      <v-snackbar
        top
        right
        fixed
        v-model="snackbarEstoque.status"
        :color="snackbarEstoque.color"
        timeout="-1"
        elevation="24"
      >
        <h4>{{ snackbarEstoque.title }}</h4>
        <h5>{{ snackbarEstoque.text }}</h5>
        <v-progress-linear indeterminate color="white darken-2" />
      </v-snackbar>

      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              <v-icon dark> mdi-printer </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="exportPdf">
              <v-list-item-title>Exportar PDF</v-list-item-title>
            </v-list-item>
            <v-list-item @click="exportFile">
              <v-list-item-title>Exportar Excel</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <TransferenciaAjuste
          class="ml-3"
          @getData="getData"
          @setSnackbarEstoque="setSnackbarEstoque"
        />
      </v-toolbar>

      <v-row class="mt-3">
        <v-col class="py-0 mb-2 col-12 col-md-2">
          <DatePicker label="Data Início" v-model="dataInicioFilter" @input="fieldsSelected()"/>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-2">
          <DatePicker label="Data Fim"  v-model="dataFimFilter" @input="fieldsSelected()"/>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <EmpresasSelect v-model="empresaFilterValue" @input="fieldsSelected"></EmpresasSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <ProducoesSelect v-model="producoesFilterValue" :empresa="empresaFilterValue" multiple :disabled="!empresaFilterValue" @input="fieldsSelected"></ProducoesSelect>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 mb-2 col-12 col-md-8 col-lg-8 col">
          <v-autocomplete
            v-model="produtosFilterValue"
            :items="produtosOptionsFilter"
            outlined
            dense
            item-text="descricao"
            item-value="id"
            label="Produto"
            @change="fieldsSelected"
          />
        </v-col>
        <v-col class="py-0 mb-2 col-12 col-md-3 col-lg-3 col">
          <v-select
            v-model="filterEntradaSaida"
            :items="tipoEntradaSaida"
            label="Entrada/Saída"
            item-text="descricao"
            item-value="id"
            dense
            outlined
          />
        </v-col>
        <v-col class="col-12 col-xs-12 col-sm-12 col-lg-1 py-0">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              color="info"
              class="v-btn--height-fix"
              item-text="nome"
              v-bind="attrs"
              v-on="on"
              item-value="id"
              @click="getData"
              :disabled="disabledCampos"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
        <v-col class="py-0 mb-2 col-12 col-md-4">
          <v-text-field
            dense
            outlined
            class="d-none"
            label="Pesquisar"
            append-icon="mdi-magnify"
            v-model="search"
            @keyup="getData"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            dense
            sort-by="data_movimentacao"
            sort-desc="true"
            :headers="headers"
            :items="data"
            :search="search"
            :items-per-page="$dataTableConfig.getItemsPerPage()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :page="page"
            :page-count="pageCount"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.tipo_movimentacao`]="{ item }">
              {{ tipoMovimentacao(item.tipo_movimentacao) }}
            </template>

            <template v-slot:[`item.data_movimentacao`]="{ item }">
              {{ formatDate(item.data_movimentacao) }}
            </template>

            <template v-slot:[`item.quantidade_fisica`]="{ item }">
              <span class="num_margin">{{
                formatNumber(item.quantidade_fisica, 2)
              }}</span>
            </template>
            <template v-slot:[`item.quantidade_fiscal`]="{ item }">
              <span class="num_margin">{{
                formatNumber(item.quantidade_fiscal, 4)
              }}</span>
            </template>

            <template v-slot:[`item.valor_produto`]="{ item }">
              <span class="num_margin">{{
                formatNumber(item.valor_produto, 2)
              }}</span>
            </template>

            <template v-slot:[`item.entrada_saida`]="{ item }">
              {{ item.entrada_saida == 'E' ? "Entrada" : "Saída" }}
            </template>

            <template v-slot:no-data>
              <v-spacer />
              <div>
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert
                      text="Não foi possível obter essas informações"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>

import TransferenciaAjuste from './components/TransferenciaAjusteComponent'

import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  components: { TransferenciaAjuste },
  data: () => ({
    valid: '',
    snackbarDownload: false,
    snackbarDownloadColor: 'success',
    snackDownloadTitle: '',
    snackDownloadText: '',
    pendingSearch: false,

    snackbarEstoque: { status: false, color: '', title: '', text: '' },

    snackText: '',
    search: '',
    loading: false,
    data: [],

    dataInicioFilter: '',
    dataFimFilter: '',
    empresasOptionsFilter: [{ nome: 'Todas', id: '' }],
    producoesOptionsFilter: [{ sigla: 'Todas', id: '' }],
    produtosOptionsFilter: [{ id: '', descricao: 'Todos' }],
    tipoEntradaSaida: [
      { id: '', descricao: 'Todos' },
      { id: 'E', descricao: 'Entrada' },
      { id: 'S', descricao: 'Saída' },

    ],

    empresaFilterValue: '',
    producoesFilterValue: '',
    produtosFilterValue: '',
    filterEntradaSaida: '',

    page: 1,
    pageCount: 0,
    options: {},
    totalItems: 0,

    producoesList: [],
  }),
  computed: {
    filter: (value) => {
      if (!this.status) {
        return true
      }

      return value < parseInt(this.status)
    },
    filteredData () {
      const data = this.data
      return data.filter((d) => d.status == 1)
    },
    headers () {
      return [
        { text: 'Empresa', value: 'empresa_nome', class: 'custom-table--header' },
        { text: 'Produção', value: 'producao_sigla', class: 'custom-table--header' },
        { text: 'Produto', value: 'produto_nome', class: 'custom-table--header' },
        { text: 'Data', value: 'data_movimentacao', class: 'custom-table--header' },
        { text: 'Operação', value: 'tipo_movimentacao', class: 'custom-table--header' },
        { text: 'E/S', value: 'entrada_saida', class: 'custom-table--header' },
        { text: 'Quantidade Física', value: 'quantidade_fisica', align: 'right', class: 'custom-table--header' },
        { text: 'Quantidade Fiscal', value: 'quantidade_fiscal', align: 'right', class: 'custom-table--header' },
        { text: 'Unidade', value: 'unidade_medida', align: 'left', class: 'custom-table--header' },
        { text: 'Valor (R$)', value: 'valor_produto', align: 'right', class: 'custom-table--header' },
      ]
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },
  created () {},
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Movimento de Estoque' },
    ])

    this.initialize()
    this.dataInicioFilter = this.$stringFormat.dateToISO(this.$date.getDateNow())
    this.dataFimFilter = this.$stringFormat.dateToISO(this.$date.getDateNow())
  },
  methods: {
    async initialize () {
      await this.getProdutos()
      this.getEmpresasOptions()
    },
    fieldsSelected () {
      this.pendingSearch = true
    },

    keyData (value) {
      var parts = value.split('-')

      if (parseInt(parts[0]) > 2000 && value.length == 10) {
        this.getData()
      }
    },

    getData () {
      this.loading = true
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = {
        page,
        qtde: itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
        empresa_id: this.empresaFilterValue,
        producao_id: this.producoesFilterValue,
        produto_id: this.produtosFilterValue,
        data_inicio: this.dataInicioFilter,
        data_fim: this.dataFimFilter,
        entradaSaida: this.filterEntradaSaida,
      }

      ApiService.get('/sapiens/movimento-estoque', '?' + this.$stringFormat.queryParamsRecursive(params)).then((res) => {
        const dados = res.data
        this.loading = false
        this.totalItems = dados.total
        this.pageCount = dados.pageCount
        this.data = dados.data
      })
    },

    getEmpresasOptions () {
      this.empresasOptionsFilter = this.$empresas.list()
    },

    async getProdutos () {
      ApiService.get('/sapiens/produto').then((res) => {
        const produtos = res.data.data

        for (const produto of produtos) {
          this.produtosOptionsFilter.push({
            id: produto.id,
            descricao: `${produto.descricao} (${produto.unidade_medida})`,
          })
        }
      })
    },

    getProdutoID (produto_id) {
      const pos = this.produtosOptionsFilter.findIndex((x) => x.id == produto_id)
      return pos > 0 ? this.produtosOptionsFilter[pos].descricao : ''
    },

    setProducoesOptions (idEmpresa) {
      this.pendingSearch = true
      this.producaoOptionsFilter = []

      if (idEmpresa != 'Todas') {
        for (const empresa of this.empresasOptionsFilter) {
          if (idEmpresa == empresa.nome) {
            idEmpresa = empresa.id
          }
        }

        ApiService.get('/producao').then((res) => {
          const producoes_filtro = []
          producoes_filtro.push({ sigla: 'Todas', id: '' })

          for (const producao of res.data.data) {
            if (producao.empresa_id == idEmpresa) {
              producoes_filtro.push({
                id: producao.id,
                sigla: producao.sigla,
              })
            }
          }

          this.producoesOptionsFilter = producoes_filtro
          this.producoesFilterValue = 'Todas'
        })
      } else {
        const producoes_filtro = []
        producoes_filtro.push({ sigla: 'Todas', id: '' })
        this.producoesOptionsFilter = producoes_filtro
        this.producoesFilterValue = 'Todas'
      }
    },

    formatDate (date) {
      date = date.substring(0, 10)

      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    formatNumber (number, decimal) {
      return parseFloat(number).toLocaleString('pt-br', {
        minimumFractionDigits: decimal,
      })
    },

    periodoData (valor) {
      if (this.dataInicioFilter == '' && this.dataFimFilter == '') {
        return valor
      } else if (valor >= this.dataInicioFilter && this.dataFimFilter == '') {
        return valor
      } else if (valor <= this.dataFimFilter && this.dataInicioFilter == '') {
        return valor
      } else if (
        valor >= this.dataInicioFilter &&
        valor <= this.dataFimFilter
      ) {
        return valor
      }
    },

    tipoMovimentacao (valor) {
      switch (valor) {
        case 'SI':
          return 'Saldo Inicial'
          break
        case 'EA':
          return 'Entrada para Ajuste'
          break
        case 'EE':
          return 'Entrada de Estoque'
          break
        case 'ET':
          return 'Entrada por Transf.'
          break
        case 'BA':
          return 'Baixa para Ajuste'
          break
        case 'BE':
          return 'Baixa de Estoque'
          break
        case 'BT':
          return 'Baixa por Transf.'
          break
        case 'RE':
          return 'Retorno Empr&eacutest.'
          break
        case 'SE':
          return 'Sa&iacuteda Empr&eacutest.'
          break
        case 'NO':
          return 'Normal'
          break
        case 'NR':
          return 'Normal e Reservado'
          break
        case 'NB':
          return 'Normal e Bloqueado'
          break
        case 'RE':
          return 'Reservado'
          break
        case 'BL':
          return 'Bloqueado'
          break
        case 'CC':
          return 'Consignado à Cliente'
          break
        case 'CF':
          return 'Consignado de Fornecedor'
          break
        default:
          return valor
          break
      }
    },

    async exportFile () {
      if (this.data.length > 0) {
        const filtros = {
          empresa_id:
            this.empresaFilterValue > 0 ? this.empresaFilterValue : '0',
          producao_id:
            this.producoesFilterValue.toString() != '' ? this.producoesFilterValue.toString() : '0',
          produto_id:
            this.produtosFilterValue != '' ? this.produtosFilterValue : '0',
          data_inicio:
            this.dataInicioFilter != '' ? this.dataInicioFilter : '0',
          data_fim: this.dataFimFilter != '' ? this.dataFimFilter : '0',
          search: this.search != '' ? this.search : '0',
          entradaSaida: this.filterEntradaSaida ? this.filterEntradaSaida : '0',
        }
        const params = {
          relatorio: 'MovimentoEstoque',
          tipo: 'Excel',
          parametros: JSON.stringify(filtros),
        }

        this.snackbarDownload = true
        this.snackbarDownloadColor = 'success'
        this.snackDownloadTitle = 'Gerando o arquivo'

        this.snackDownloadText =
          'Aguarde alguns segundos para baixar o arquivo'

        try {
          ApiService.post('/relatorios/exportar', params).then((res) => {
            if (res.data.data.arquivo != '') {
              this.snackDownloadTitle = 'Arquivo gerado com sucesso'
              this.snackDownloadText = ''
              window.open(res.data.data.arquivo, '_blank')
            }

            setTimeout(() => (this.snackbarDownload = false), 3000)
          })
        } catch (error) {
          setTimeout(() => (this.snackbarDownload = false), 3000)
          this.snackbarDownloadColor = 'red'
          this.snackDownloadTitle = 'Error'

          this.snackDownloadText =
            'Não foi possível gerar o arquivo, tente novamente mais tarde.'

          console.error('Erro: ', error)
        }
      } else {
        this.snackbarDownload = true
        this.snackbarDownloadColor = 'red'
        this.snackDownloadTitle = 'Erro'
        this.snackDownloadText = 'Não foram encontrados dados para exportação!'
        setTimeout(() => (this.snackbarDownload = false), 3000)
      }
    },
    async exportPdf () {
      if (this.data.length > 0) {
        this.snackbarDownload = true
        this.snackbarDownloadColor = 'success'
        this.snackDownloadTitle = 'Gerando o arquivo'

        this.snackDownloadText =
          'Aguarde alguns segundos para baixar o arquivo'

        let param = `?empresa_id=${this.empresaFilterValue > 0 ? this.empresaFilterValue : '0'}`
        param += `&producao_id=${this.producoesFilterValue.toString() != '' ? this.producoesFilterValue.toString() : '0'}`
        param += `&produto_id=${this.produtosFilterValue != '' ? this.produtosFilterValue : '0'}`
        param += `&data_inicio=${this.dataInicioFilter != '' ? this.dataInicioFilter : '0'}`
        param += `&data_fim=${this.dataFimFilter != '' ? this.dataFimFilter : '0'}`
        param += `&search=${this.search != '' ? this.search : '0'}`
        param += `&entradaSaida=${this.filterEntradaSaida ? this.filterEntradaSaida : '0'}`

        this.snackbarDownload = true
        this.snackbarDownloadColor = 'success'
        this.snackDownloadTitle = 'Gerando o arquivo'

        this.snackDownloadText =
          'Aguarde alguns segundos para baixar o arquivo'

        try {
          ApiService.get('reports/movimento-estoque/relatorio-pdf', param).then((res) => {
            if (res.status === 200) {
              this.snackDownloadTitle = 'Arquivo gerado com sucesso'
              this.snackDownloadText = ''
              const newWindow = window.open()
              newWindow.document.write(res.data)
            }

            setTimeout(() => (this.snackbarDownload = false), 3000)
          })
        } catch (error) {
          setTimeout(() => (this.snackbarDownload = false), 3000)
          this.snackbarDownloadColor = 'red'
          this.snackDownloadTitle = 'Error'

          this.snackDownloadText =
            'Não foi possível gerar o arquivo, tente novamente mais tarde.'

          console.error('Erro: ', error)
        }
      } else {
        this.snackbarDownload = true
        this.snackbarDownloadColor = 'red'
        this.snackDownloadTitle = 'Erro'
        this.snackDownloadText = 'Não foram encontrados dados para exportação!'
        setTimeout(() => (this.snackbarDownload = false), 3000)
      }
    },
    setSnackbarEstoque ({ color, title, text, timeout }) {
      this.snackbarEstoque.status = true
      this.snackbarEstoque.color = color
      this.snackbarEstoque.title = title
      this.snackbarEstoque.text = text

      if (timeout > 0) {
        setTimeout(() => (this.snackbarEstoque.status = false), timeout)
      }
    },
  },
}
</script>
