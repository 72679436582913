<template>
  <div>
    <v-dialog v-model="dialog" transition="dialog-bottom-transition" :max-width="500">
      <template v-slot:activator="{ on, attrs }"
      > <v-col class="px-0"
          v-if="$auth.hasPermission('movimento-estoque.ajuste')"
          >
        <v-btn color="info" dark outlined v-bind="attrs" v-on="on">
          Ajuste de Estoque
        </v-btn>
        </v-col>
      </template>

      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Ajuste de Estoque Físico</span>
        </v-toolbar>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container class="mt-3">

            <template>
              <v-row>
                <v-col class="col-12">
                  <EmpresasSelect
                    v-model="empresa_id"
                    obrigatorio
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12">
                  <ProducoesSelect
                    v-model="producao_id"
                    :empresa="empresa_id"
                    obrigatorio
                    @change="setProdutos(empresa_id, producao_id)"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12">
                  <v-autocomplete
                    v-model="produto_id"
                    :items="produtosOrigemOptions"
                    label="Produtos"
                    item-text="descricao"
                    item-value="id"
                    dense
                    outlined
                    @change="setUnidadeMedida(produto_id)"
                    :rules="produtoOrigemRule"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12">
                  <v-select
                    v-model="tipo_ajuste"
                    :items="tipoAjusteOptions"
                    label="Tipo Ajuste"
                    item-text="descricao"
                    item-value="id"
                    dense
                    outlined
                    :rules="tipoAjusteRule"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12">
                  <v-text-field
                    v-model="quantidade"
                    dense
                    outlined
                    :label="`Quantidade (${unidade_medida})`"
                    reverse
                    :rules="[quantidadeRule]"
                  />
                </v-col>
              </v-row>
            </template>
          </v-container>

          <v-card-actions v-show="!loading">
            <v-spacer />
            <v-btn color="blue darken-1" text @click="close"> Cancelar </v-btn>
            <v-btn color="blue darken-1" text @click="validate"> Salvar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogEstoque.status" transition="dialog-bottom-transition" max-width="400">
      <v-card>
        <v-toolbar color="info" dark>
          <v-spacer />
          <span class="headline">{{ dialogEstoque.titulo }}</span>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <div class="text-center p-5">
            <h4 class="my-4">
              {{ dialogEstoque.mensagem }}
            </h4>
            <v-progress-circular
              :size="100"
              :width="2"
              color="primary"
              indeterminate
              v-if="dialogEstoque.loading"
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeEstoque"
            v-if="dialogEstoque.button"
          >
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import ApiService from '@/core/services/api.service'

export default {
  props: {},
  data: () => ({
    dialog: false,
    valid: '',
    maskQtde: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 4,
    },

    operacaoRule: [(v) => !!v || 'Selecione uma Operação'],
    empresaOrigemRule: [(v) => !!v || 'Selecione uma Empresa'],
    producaoOrigemRule: [(v) => !!v || 'Selecione uma Produção'],
    produtoOrigemRule: [(v) => !!v || 'Selecione um Produto'],
    tipoAjusteRule: [(v) => !!v || 'Selecione o Tipo de Ajuste'],
    operacaoOptions: [
      { id: 1, descricao: 'Transferência' },
      { id: 2, descricao: 'Ajuste de Estoque' },
    ],
    empresasOptions: [{ nome: 'Selecione uma Empresa', id: '' }],
    producoesOrigemOptions: [{ sigla: 'Seleciona uma Produção', id: '' }],
    produtosOrigemOptions: [{ id: '', descricao: 'Selecione um Produto' }],
    tipoAjusteOptions: [
      { id: 'entradaEstoque', descricao: 'Entrada por acerto de estoque' },
      { id: 'saidaEstoque', descricao: 'Saída por acerto de estoque' },
    ],
    empresa_id: '',
    producao_id: '',
    produto_id: '',
    tipo_ajuste: '',
    quantidade: '',
    unidade_medida: '-',

    dialogEstoque: {
      status: false,
      titulo: '',
      mensagem: 'Aguarde...',
      loading: false,
      button: false,
    },
    loadingEstoque: false,
  }),
  computed: {
    quantidadeRule () {
      let valid = true
      let msg = ''
      let quantidade = this.quantidade.toString()
      quantidade = parseFloat(quantidade.replace('.', '').replace(',', '.'))

      if (quantidade <= 0 && this.unidade_medida !== '-') {
        valid = false
        msg = 'Informe uma Quantidade'
      }

      return () => valid || `${msg}`
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  mounted () {
    this.getEmpresasOptions()
    this.getProducoesList()
  },
  methods: {
    getEmpresasOptions () {
      this.empresasOptions = this.$empresas.list(false)
    },

    getProducoesList () {
      ApiService.get('/producao').then((res) => {
        this.producoesList = res.data.data
      })
    },

    setProdutos (empresaId, producaoId) {
      if (!producaoId) {
        return
      }

      let produtosOptions = []
      produtosOptions.push({ id: '', descricao: 'Selecione um Produto' })

      this.produtosOrigemOptions = [{ id: '', descricao: 'Carregando...' }]
      this.produto_id = ''

      try {
        ApiService.get(`/sapiens/produto/${empresaId}/${producaoId}`).then(
          (res) => {
            const produtos = res.data.data

            for (const produto of produtos) {
              produtosOptions.push(produto)
            }

            if (produtosOptions.length <= 1) {
              produtosOptions = [
                { id: '', descricao: 'Nenhum produto/deposito encontrado para a Produção' },
              ]
            }

            this.produtosOrigemOptions = produtosOptions
            this.produto_id = ''
          },
        )
      } catch (error) {
        produtosOptions = [{ id: '', descricao: 'Nenhum produto/deposito encontrado para a Produção' }]

        this.produtosOrigemOptions = produtosOptions
        this.produto_id = ''
      }
    },

    setUnidadeMedida (produtoId) {
      let produto = []

      produto = this.produtosOrigemOptions.filter((e) => e.id === produtoId)
      this.unidade_medida = produto[0].unidade_medida
    },

    setOperacao () {
      this.empresa_id = ''
      this.empresa_destino_id = ''
      this.producao_id = ''
      this.producao_destino_id = ''
      this.produto_id = ''
      this.quantidade = ''
      this.unidade_medida = '-'

      this.producoesDestinoOptions = [
        { sigla: 'Seleciona uma Produção', id: '' },
      ]

      this.produtosDestinoOptions = [
        { id: '', descricao: 'Selecione um Produto' },
      ]

      this.producoesOrigemOptions = [
        { sigla: 'Seleciona uma Produção', id: '' },
      ]

      this.produtosOrigemOptions = [
        { id: '', descricao: 'Selecione um Produto' },
      ]
    },

    close () {
      this.dialog = false
      this.empresa_id = ''
      this.producao_id = ''
      this.produto_id = []
      this.quantidade = ''
      this.unidade_medida = '-'
      this.tipo_ajuste = ''

      this.$nextTick(() => {
        this.$refs.form.resetValidation()
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.editDisabled = false
      })
    },

    closeEstoque () {
      this.dialog = true
      this.dialogEstoque.status = false
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem)
      } else {
        this.data.push(this.editedItem)
      }

      this.close()
    },

    validate () {
      if (this.$refs.form.validate()) {
        this.dialog = false
        this.dialogEstoque.status = true

        this.dialogEstoque.titulo = 'Salvando Ajuste de Estoque'

        this.dialogEstoque.mensagem = 'Aguarde...'
        this.dialogEstoque.loading = true
        this.dialogEstoque.button = false

        try {
          const formData = new FormData()
          formData.append('empresa_id', this.empresa_id)
          formData.append('producao_id', this.producao_id)
          formData.append('produto_id', this.produto_id)
          formData.append('quantidade', this.quantidade)
          formData.append('tipo_ajuste', this.tipo_ajuste)

          ApiService.post('movimento-estoque', formData).then((res) => {
            if (res.data.status === 201) {
              this.dialogEstoque.status = false
              const text = 'Ajuste de Estoque salvo com sucesso'
              this.$emit('setSnackbarEstoque', { color: 'success', title: 'Sucesso', text: text, timeout: 3000 })
              this.$emit('getData', '')

              this.empresa_id = ''
              this.producao_id = ''
              this.produto_id = ''
              this.quantidade = ''
              this.unidade_medida = '-'
              this.tipo_ajuste = ''

              this.producoesOrigemOptions = [
                { sigla: 'Seleciona uma Produção', id: '' },
              ]

              this.produtosOrigemOptions = [
                { id: '', descricao: 'Selecione um Produto' },
              ]
            } else {
              this.dialogEstoque.status = false
              this.$emit('setSnackbarEstoque', { color: 'red', title: 'Erro', text: res.data.data, timeout: 3000 })
            }
          }).catch((error) => {
            this.dialogEstoque.status = false
            this.$emit('setSnackbarEstoque', { color: 'red', title: 'Erro', text: error.response.data.errors, timeout: 3000 })
          })
        } catch (error) {
          this.dialogEstoque.status = false
          const text = 'Não foi possível realizar o Ajuste de Estoque'
          this.$emit('setSnackbarEstoque', { color: 'red', title: 'Erro', text: text, timeout: 3000 })
        }
      }
    },
  },
}
</script>
